import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllProperties extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`CategoryProperty/GetAll?pageNumber=${data.pageNumber}&count=${data.count}&searchCommand=${data.search}`);
    }
}

class DeleteAcademy extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`Academy/Delete?id=${data.id}`);
    }
}


class UpdateAcademy extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Academy/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateAcademy extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Academy/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}
class GetAcademyForVendor extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Academy/GetAcademiessForVendor');
    }
    setParams(data) {
        super.setRequestParam(data)
    }
}
class GetAcademyById extends WebServiceRequest {
    constructor(context) {
        super(context)
    }
    setParams(data) {
        super.setTag(`Academy/Get?academyId=${data.academyId}`);
    }

}
class GetAcademyByIdForVendor extends WebServiceRequest {
    constructor(context) {
        super(context)
    }
    setParams(data) {
        super.setTag(`Academy/GetForVendor?productId=${data.academyId}`);
    }

}
class GetProductCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`ProductCategory/Get`);
    }
    setParams(data) {
        super.setRequestParam(data)
    }

}
class UpdateProductCategorySEO extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`ProductCategory/UpdateSEO`);
    }
    setData(data) {
        super.setRequestParamDataObj(data)
    }

}
class UpdateProductSEO extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Product/UpdateSEO`)
    }
    setData(data) {
        super.setRequestParamDataObj(data);
    }

}
class UpdateAcademyPrice extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('AcademyPrice/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}
class AcademyCategoryGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('AcademyCategory/GetAll')
    }
}
class CopyAcademyObjects extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Academy/CopyObject')
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}
class ChangeIsInStock extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Academy/ChangeStock')
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}
class ChangeAcademyStatus extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Academy/ChangeStatus')
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class ListCourseMedias extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Academy/ListAllMedias')
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}
class MediaDeleteByPictureUrlRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Media/DeleteMediasBy')
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}
class GetAcademyStudents extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Academy/GetAcademiessForVendor')

    }
    // setId (id){
    //     super.setTag(`Academy/GetAcademiessForVendor`)
    // }
    setParams(data) {
        super.setRequestParam(data);
    }
}
export {
    ListCourseMedias,
    GetAcademyStudents,
    UpdateProductSEO,
    ChangeAcademyStatus,
    GetAllProperties,
    CopyAcademyObjects,
    GetAcademyByIdForVendor,
    ChangeIsInStock,
    GetProductCategory,
    UpdateProductCategorySEO,
    GetAcademyForVendor,
    GetAcademyById,
    DeleteAcademy,
    MediaDeleteByPictureUrlRequest,
    UpdateAcademy,
    UpdateAcademyPrice,
    CreateAcademy,
    AcademyCategoryGetAllRequest
};
